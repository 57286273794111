
import { Options, Vue } from "vue-class-component";
import { UserController } from "@/app/ui/controllers/UserController";

import {
  checkRolePermission,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { USER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { AuthController } from "@/app/ui/controllers/AuthController";
import ModalEditDetail from "@/app/ui/views/user-management/component/modal-edit-detail.vue";
import isAssigned3lc from "@/app/infrastructures/misc/common-library/isAssigned3lc";

@Options({
  components: {
    ModalEditDetail
  }
})
export default class DetailUser extends Vue {
  async created() {
    this.fetchUserData();
  }

  fetchUserData() {
    this.getDetailUserData(this.$route.params.id);
  }

  get isEditAble() {
    return checkRolePermission(USER_MANAGEMENT.EDIT);
  }

  get isAbleChangeRole() {
    return checkRolePermission(USER_MANAGEMENT.CHANGE_ROLE);
  }

  get isAbleChangeEmail() {
    return checkRolePermission(USER_MANAGEMENT.CHANGE_EMAIL);
  }

  get isAbleChangePassword() {
    return checkRolePermission(USER_MANAGEMENT.CHANGE_PASSWORD);
  }
  //Loading
  get isLoading() {
    return UserController.isLoadingDetail;
  }

  //Error
  get isError() {
    return UserController.isError;
  }

  //Error Cause
  get errorCause() {
    return UserController.errorCause;
  }

  getDetailUserData(id: any) {
    return UserController.getDetailUser({ id });
  }

  get dataDetail() {
    return UserController.userDetail;
  }

  get displayAssigned3lc() {
    return this.dataDetail.assigned3lc === "ALL"
      ? "Semua"
      : this.dataDetail.assigned3lc?.split(",")?.join(", ");
  }

  goBack() {
    this.$router.push(`/user-management`);
  }

  item = {
    status: "Inactive"
  };

  goEdit(id: string) {
    this.$router.push(`/user-management/edit/${id}`);
  }

  formatDate(date: string) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz === "Asia/Jakarta") {
      return this.$moment(date).format("DD MMMM YYYY, HH:mm") + " WIB";
    } else if (tz === "Asia/Pontianak") {
      return this.$moment(date).format("DD MMMM YYYY, HH:mm") + " WITA";
    } else if (tz === "Asia/Jayapura") {
      return this.$moment(date).format("DD MMMM YYYY, HH:mm") + " WITA";
    }
  }

  // reset password
  confirmationResetPassword() {
    MainAppController.setSnackbar(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Konfirmasi Perubahan",
        message: `Anda baru saja mengajukan pengaturan ulang password baru. Password baru akan kami kirimkan ke email <span class="text-blue-600 underline">${this.dataDetail.email}</span>.`,
        textCancel: "Kembali",
        textSuccess: "Reset",
        onSubmit: () => this.submitResetPassword(),
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitResetPassword() {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    MainAppController.closeMessageModal();
    const response = await AuthController.sendEmail({
      email: this.dataDetail.email
    });
    MainAppController.closeLoading();
    if (response.success) {
      MainAppController.setSnackbarMessage(
        `Pengaturan ulang password baru berhasil. Password baru telah terkirim ke ${this.dataDetail.email}`
      );
      MainAppController.setSnackbar(true);
    } else {
      MainAppController.showErrorMessage(
        parsingErrorResponse(response.resp, "Reset Password Gagal")
      );
    }
  }

  modalEditDetail = false;
  typeEdit = "";
  async openModalEditDetail(type: string) {
    if (type === "role") {
      this.onGetRole(type);
    } else {
      MainAppController.setSnackbar(false);
      this.modalEditDetail = true;
      this.typeEdit = type;
    }
  }
  async onGetRole(type: string) {
    try {
      MainAppController.showLoading();
      UserController.setError(false);

      await UserController.getRole({
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_type: this.dataDetail.account_type
      });

      MainAppController.setSnackbar(false);
      this.typeEdit = type;
      this.modalEditDetail = true;
    } catch (error) {
      UserController.setError(true);
    } finally {
      MainAppController.closeLoading();
    }
  }

  get isInternalDataEntry(): boolean {
    return isAssigned3lc({ accountType: this.dataDetail.account_type, roleName: this.dataDetail.role_name })
  }
}
