
import { Vue, Options } from "vue-class-component";
import {
  formatInputNumberOnlyV2,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import SelectPhoneCountry from "../../shipment/booking/component/select-phone-country.vue";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CustomerManagementController } from "@/app/ui/controllers/CustomerManagementController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { Customer } from "@/domain/entities/Customer";
import { ApiStateData } from "@/domain/entities/Api";
import { countries } from "@/app/infrastructures/misc/BulkData";
import { Commodity } from "@/domain/entities/Commodity";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { ValidateInputPhoneNumber } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";

@Options({
  components: {
    SelectPhoneCountry
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.name = "";
      next();
    }
  }
})
export default class EditManagementCustomer extends Vue {
  countryFiltered = countries;
  apiListData = new ApiStateData();
  customer: Customer = new Customer();
  id = 0;
  firstLanding = true;

  mounted(): void {
    this.id = Number(this.$route.query.id || 0);
    this.getCustomerDetail();
  }

  async getCustomerDetail() {
    this.apiListData.loading = true;
    try {
      const resp = await CustomerManagementController.getCustomerDetail(
        this.id
      );
      this.name = resp.customerName;
      this.extractPhoneDetails(resp.customerPhoneNumber, countries);
      if (resp.customerDistrictId != 0) {
        this.fetchDistrictId(resp.customerDistrictId);
      }
      if (resp.customerZipCode != "") {
        this.zipCode = {
          id: Number(resp.customerZipCode),
          name: resp.customerZipCode
        };
      }
      if (resp.customerCommodity.name) {
        this.getCommodityList();
      }
      if (resp.customerCommodity.name) {
        this.commodity = resp.customerCommodity;
      }
      this.email = resp.customerEmail;
      this.note = resp.customerNote;
      this.address = resp.customerAddress;
      this.addressType = resp.customerAddressType;
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
      this.firstLanding = false;
    }
  }

  goBack() {
    this.$router.back();
  }

  get isNoteValid() {
    return this.note.length > 2 && this.note.length <= 200;
  }

  get title(): string {
    return (this.$route as any)?.meta?.name;
  }

  get isFormValid() {
    return true;
  }

  isDisabled = true;

  error = {
    phoneNumber: false,
    phoneNumberCode: false,
    successPhoneNumber: false,
    errorMessagePhoneNumber: false,
    email: false
  };

  // validation name
  name = "";
  validName = true;

  validateName() {
    this.validName = this.name.length !== 0;
  }

  // phone number
  phoneNumber = "";
  phoneNumberCode = "+62";
  validPhoneNumber = true;

  filterInput(e: any) {
    e.target.value = formatInputNumberOnlyV2(e.target.value);
    this.validatePhoneNumber();
  }

  validatePhoneNumber() {
    this.validPhoneNumber = this.phoneNumber.length !== 0;
  }

  errorPhoneSender = {
    success: false,
    errorPhone: false,
    errorMandatory: false,
    errorPhoneCode: false,
    message: ""
  };

  onvalidatePhoneNumber() {
    return ValidateInputPhoneNumber(
      this.phoneNumberCode?.replace(/\+/, "") + this.phoneNumber
    );
  }
  setPhoneNumber(val: string) {
    this.phoneNumber = val;
    const { error, message } = this.onvalidatePhoneNumber();

    this.errorPhoneSender = {
      ...this.errorPhoneSender,
      success: !this.errorPhoneSender.errorPhoneCode,
      errorPhone: error,
      message
    };
  }
  setPhoneCodeNumber(val: string) {
    this.phoneNumberCode = val;
  }

  setErrorPhoneNumberCode(value: boolean) {
    this.errorPhoneSender = {
      ...this.errorPhoneSender,
      success: !value,
      errorPhoneCode: value
    };
  }

  extractPhoneDetails(number: string, countries: { code: string }[]) {
    const matchingCountry = countries.find((country) =>
      number.startsWith(country.code.replace("+", ""))
    );

    const phoneCode: string = matchingCountry
      ? matchingCountry.code.replace("+", "")
      : "";
    const phoneNumber: string = number.slice(phoneCode.length);

    this.phoneNumberCode = "+" + phoneCode;
    this.phoneNumber = phoneNumber;
  }

  // validation email
  email = "";
  note = "";
  address = "";
  commodity: any = "";
  validAddress = true;
  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  validateAddress() {
    this.validAddress = this.address.length !== 0;
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }

  get errorMandatory() {
    return [
      !!this.errorName && this.currentFieldIndex > 0,
      !!this.errorPhone && this.currentFieldIndex > 1,
      false, // no used
      false, // no used
      false, // no used
      !!this.errorAddress && this.currentFieldIndex > 5,
      false, // no used
      false, // no used
      false // no used
    ];
  }

  get errorName() {
    if (!this.name) {
      return "Nama wajib diisi";
    }

    if (this.name.length < 3) {
      return "Nama minimum 3 karakter";
    }

    if (this.name.length > 100) {
      return "Nama maksimum 100 karakter";
    }

    return "";
  }

  get errorAddress() {
    if (!this.address) {
      return "Alamat wajib diisi";
    }

    if (this.address.length < 3) {
      return "Alamat minimum 3 karakter";
    }

    if (this.address.length > 200) {
      return "Alamat maksimum 200 karakter";
    }

    return "";
  }

  get errorNotes() {
    if (this.note && this.note.length < 3) {
      return "Catatan minimum 3 karakter";
    }

    if (this.note.length > 200) {
      return "Catatan maksimum 200 karakter";
    }

    return "";
  }

  get errorPhone(): string {
    if (!this.phoneNumber) {
      this.errorPhoneSender.errorMandatory = this.currentFieldIndex > 1;
      return "No. telepon wajib diisi";
    }
    this.errorPhoneSender.errorMandatory = false;

    if (this.errorPhoneSender.errorPhone) {
      return String(this.errorPhoneSender.message);
    }

    if (this.errorPhoneSender.errorPhoneCode) {
      return "error code phone"; // tidak ditampilkan, hanya untuk disable button
    }

    return "";
  }

  // city
  destinationCity: any = "";
  destinationCityList: Array<any> = [];
  onFocusdestinationCity() {
    this.setFieldIndex(7);
    if (!this.destinationCityList.length || !this.destinationCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchdestinationCity("");
    }
  }
  fetchdestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingdestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.originZipCode}`
      }));
  }
  async fetchDistrictId(search: number) {
    if (search) {
      const responseDistrictData = await LocationController.getDetailDistrict({
        id: search,
        notSaveDetail: true
      });
      const disctrictName =
        responseDistrictData.name + "," + responseDistrictData.cityName;
      this.destinationCity =
        responseDistrictData.name == null
          ? ""
          : { id: search, name: disctrictName };
    } else {
      this.destinationCity = "";
    }
  }

  onChangeDestinationCity() {
    if (!this.firstLanding) {
      if (this.destinationCity.code?.includes(",")) {
        const arrayZipcode: string[] = this.destinationCity.code.split(",");
        this.zipCodeList = arrayZipcode.map((e: any) => ({
          code: e,
          name: e
        }));
      } else {
        this.zipCode = this.destinationCity.code
          ? { code: this.destinationCity.code, name: this.destinationCity.code }
          : this.zipCode;
        this.zipCodeList = [
          { code: this.destinationCity.code, name: this.destinationCity.code }
        ];
      }
    }
  }

  //zipcode
  zipCode: any = "";
  zipCodeList: Array<any> = [];

  fetchZipCode = debounce(async (search: string) => {
    if (!this.destinationCity.code) {
      const response = await LocationController.getZipCodeList({
        search: search,
        page: 1,
        limit: 10
      });
      this.zipCodeList = this.filterZipCode(response);
    }
  }, 250);

  filterZipCode(response: any) {
    return response.zipCodeData.map((e: any) => ({
      code: e.originZipCode,
      name: e.originZipCode,
      id: e.id,
      key: `${e.name}, ${e.cityName}`
    }));
  }

  get isLoadingZipCode() {
    return LocationController.isLoadingZipCode;
  }

  onFocusZipCode() {
    this.setFieldIndex(8);
    if (!this.zipCodeList.length || !this.zipCode) {
      this.fetchZipCode("");
    }
  }

  onChangeZipCode() {
    if (!this.destinationCity.code && !this.firstLanding) {
      this.destinationCity = this.zipCode.name
        ? { id: this.zipCode.id, name: this.zipCode.key }
        : "";
    }
  }

  //address type

  addressType = "";
  addressTypeSelect = false;
  addressTypeData = [
    { name: "Pilih tipe alamat ", value: "" },
    { name: "Rumah", value: "rumah" },
    { name: "Kantor", value: "kantor" }
  ];

  onOpenAddressType() {
    this.setFieldIndex(9);
    this.addressTypeSelect = true;
  }

  onCloseAddressType() {
    this.addressTypeSelect = false;
  }

  onSelectAddressType(name: string, value: string) {
    this.addressType = value;
  }

  get isSubmitLoading(): boolean {
    return CustomerManagementController.isSubmitLoading;
  }

  get isErrorForm() {
    return (
      !!this.errorName ||
      !!this.errorPhone ||
      !!this.errorNotes ||
      !!this.errorAddress ||
      this.error.email
    );
  }

  get openModalConfirmation() {
    return CustomerManagementController.openModalConfirmation;
  }

  openConfirmationModal() {
    this.setOpenModalConfirmation(true);
  }

  setOpenModalConfirmation(open: boolean) {
    CustomerManagementController.setOpenModalConfirmation(open);
  }

  handlerSubmit() {
    this.setOpenModalConfirmation(false);
    this._onEdit();
  }

  onCloseModal() {
    this.setOpenModalConfirmation(false);
  }

  openSnackbar(name: string) {
    MainAppController.setSnackbarMessage(
      `Perubahaan data pelanggan "${name}" berhasil!`
    );
    MainAppController.setSnackbar(true);
  }

  // commodity
  dataListCommodities: Commodity[] = [];
  async getCommodityList() {
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        page: 1,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities = commodities.data;
  }

  fetchCommodityList = debounce(async (search: string) => {
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
        page: 1,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities = commodities.data;
  }, 250);

  async fetchCommodityListInfiniteScroll(search = "") {
    CommodityController.commodities.pagination.page++;
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        search,
        page: CommodityController.commodities.pagination.page,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities.push(...commodities.data);
  }
  get commodities() {
    return this.dataListCommodities.map((item) => {
      return {
        id: item.commodity_id,
        name: item.commodity_name,
        code: item.commodity_code,
        status: item.commodity_status,
        isDangerousGoods: item.isDangerousGoods,
        isQuarantine: item.isQuarantine
      };
    });
  }
  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  _onEdit() {
    CustomerManagementController._onEditCustomer({
      id: this.id,
      name: this.name,
      phone: `${this.phoneNumberCode.substring(1)}${this.phoneNumber}`,
      email: this.email,
      address: this.address,
      districtId: Number(this.destinationCity.id),
      zipCode: this.zipCode.name,
      addressType: this.addressType.toLocaleLowerCase(),
      note: this.note,
      commodityCode: this.commodity.code
    }).then(() => {
      const customerName = this.name;
      this.$router.push(`/management-customer`).then(() => {
        this.openSnackbar(customerName);
      });
    });
  }

  get openModalDelete() {
    return CustomerManagementController.openModalDelete;
  }

  openDeleteModal() {
    this.setOpenModalDelete(true);
  }

  setOpenModalDelete(open: boolean) {
    CustomerManagementController.setOpenModalDelete(open);
  }

  handlerDelete() {
    this.setOpenModalDelete(false);
    this._onDelete();
  }

  onCloseModalDelete() {
    this.setOpenModalDelete(false);
  }

  async _onDelete() {
    MainAppController.showLoading();
    try {
      await CustomerManagementController.deleteCustomer(this.id);
      const customerName = this.name;
      this.$router.replace(`/management-customer`).then(() => {
        MainAppController.setSnackbarMessage(
          `Penghapusan data pelanggan "${customerName}" berhasil!`
        );
        MainAppController.setSnackbarPositivity(false);
        MainAppController.setSnackbar(true);
      });
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Menghapus Customer", () => {
          MainAppController.closeErrorMessage();
          this.openDeleteModal();
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  validateEmail(value: string) {
    this.error.email =
      !!value && !!value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }
}
